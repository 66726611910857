import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule,Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { ParentPlanApiService } from '@th-common/app/core/api/parent-plan-api.service';
import { RetailerPlanApiService } from '@th-common/app/core/api/retailer-plan-api.service';
import { StoresApi } from '@th-common/app/core/api/stores.api';
import {
  BaseWizard,
  WizardNavigation,
  WizardNavigationChild,
  WizardNavigationItem,
} from '@th-common/app/core/classes/base-wizard';
import { AppConstants, RECEIPT_FILES_DROP_AREA } from '@th-common/app/core/constants/app.constants';
import { RetailerFilter } from '@th-common/app/core/enums/retailer-filter.enum';
import { QuickSearchConsumer } from '@th-common/app/core/interfaces/quick-search/search-results.interface';
import { IRetailer } from '@th-common/app/core/interfaces/stores/retailer.interface';
import { Identity, IIdentityString } from '@th-common/app/core/interfaces/util/identity.interface';
import { ConsumerForm, RetailerPlanFormClass } from '@th-common/app/core/models/retailer-plan-form.model';
import { ClaimFormService } from '@th-common/app/core/services/claim-form.service';
import { DialogService } from '@th-common/app/core/services/dialog.service';
import { RetailersListStore } from '@th-common/app/core/store/retailersList/retailersList.store';
import { UserStore } from '@th-common/app/core/store/user/user.store';
import { DateValidation } from '@th-common/app/core/utils/form.util';
import moment from 'moment';
import { distinctUntilChanged, filter, finalize, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { DIALOG_CONFIG_RETAILER_SALE } from '@core/constants/dialog.constants';
import { Files, IDropArea } from '@core/interfaces/claims/files.interface';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';
import { AlertMessageComponent } from '@shared/components/alert-message/alert-message.component';
import {
  SideDialogContainerComponent,
} from '@shared/components/dialogs/side-dialog-container/side-dialog-container.component';
import { SideDialogContentComponent } from '@shared/components/dialogs/side-dialog-content/side-dialog-content.component';
import { SideDialogNavComponent } from '@shared/components/dialogs/side-dialog-nav/side-dialog-nav.component';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ClaimDialogsCoreModule } from '@shared/modules/claim-dialogs/claim-dialogs-core.module';
import {
  AddressSuggestComponent,
} from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.component';
import { ADDRESS_SUGGEST_SCROLL_SELECTOR } from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.token';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { ClaimFormModule } from '@shared/modules/claim-form/claim-form.module';
import { DialogCoreModule } from '@shared/modules/dialog-core/dialog-core.module';
import { FilesPreviewNewComponent } from '@shared/modules/files/components/files-preview-new/files-preview-new.component';
import { NavigationModule } from '@shared/modules/navigation/navigation.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import {
  RetailerSelectComponent,
} from '@shared/modules/store-controls/components/retailer-select/retailer-select.component';
import { WizardModule } from '@shared/modules/wizard/wizard.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

import { ProtectionPlanFormComponent } from '../containers/protection-plan-form/protection-plan-form.component';
import { LicenseCaliforniaDialogComponent } from '../license-california-dialog/license-california-dialog.component';
import { LicenseFloridaDialogComponent } from '../license-florida-dialog/license-florida-dialog.component';
import { PreRegisterRetailerPlanComponent } from '../pre-register-retailer-plan/pre-register-retailer-plan.component';
import { ServiceSuspendedDialogComponent } from '../service-suspended-dialog/service-suspended-dialog.component';

export interface RegisterRetailerPlanWizard extends WizardNavigation {
  consumer: WizardNavigationItem;
  sale: WizardNavigationItem;
  plan: WizardNavigationItem;
  uploadReceipt: WizardNavigationItem;
}

export enum RegisterRetailerPlanSteps {
  Consumer,
  Sale,
  Plan,
  UploadReceipt,
}

export enum PreRegisterRetailerPlanSteps {
  Search,
  Select,
  Registration,
}

interface IRegisterRetailerPlanState {
  currentStep: WizardNavigationItem;
  currentSubStep: number;
  nextButtonDisabled: boolean;
  backButtonDisabled: boolean;
  isEmailRequired: boolean;
  consumerStepAlertMessage: string;
  addressVerificationInProgress: boolean;
}

@Component({
  selector: 'app-register-retailer-plan',
  standalone: true,
  templateUrl: './register-retailer-plan.component.html',
  styleUrls: ['./register-retailer-plan.component.scss'],
  providers: [
    RetailersListStore,
    RxState,
    SideDialogStateService,
    {
      provide: ADDRESS_SUGGEST_SCROLL_SELECTOR,
      useValue: 'app-side-dialog-content',
    },
  ],
  imports: [
    DialogCoreModule,
    NavigationModule,
    ReactiveFormsModule,
    ClaimFormModule,
    AsyncPipe,
    NgIf,
    ClaimDialogsCoreModule,
    FormInputComponent,
    MatIconModule,
    WizardModule,
    NgForOf,
    PipesModule,
    AlertMessageComponent,
    SwitchComponent,
    RetailerSelectComponent,
    FormDateComponent,
    ProtectionPlanFormComponent,
    FormFilesDropAreaComponent,
    MatButtonModule,
    AddressSuggestComponent,
    PreRegisterRetailerPlanComponent,
    SideDialogLoaderComponent,
    SideDialogAnimationComponent,
    FilesPreviewNewComponent,
    MatDialogModule,
    SideDialogNavComponent,
    SideDialogContentComponent,
    SideDialogContainerComponent,
  ],
})
export class RegisterRetailerPlanComponent extends BaseWizard<RegisterRetailerPlanWizard> implements OnInit {
  nextButtonDisabled$ = this.state.select('nextButtonDisabled');
  backButtonDisabled$ = this.state.select('backButtonDisabled');
  addressVerificationInProgress$ = this.state.select('addressVerificationInProgress');
  isEmailRequired$ = this.state.select('isEmailRequired');
  currentSubStep$ = this.state.select('currentSubStep');
  filesForm = this.fb.array<Files>([], [validateArrayMaxFilesSize()]);

  consumerStepAlertMessage$ = this.state.select('consumerStepAlertMessage');

  loading = false;
  nextButtonInProgress = false;
  steps = RegisterRetailerPlanSteps;
  preSteps = PreRegisterRetailerPlanSteps;
  minDate: any = AppConstants.minDateDatepicker;
  today = new Date();
  purchaseDateInitial = this.today;
  oldestPurchaseDateMonths = this.userStore.get('session', 'oldestPurchaseDateMonths');
  minPurchaseDate = new Date(new Date().setMonth(this.today.getMonth() - this.oldestPurchaseDateMonths));
  deliveryDateInitial: Date;

  selectedConsumer: ConsumerForm;
  selectedPlansIdsList: string[] = [];
  plans: Array<IIdentityString> = [];
  plansNew: Array<string> = [];

  selectedRetailer: IRetailer;
  selectedRetailerAddress: string;

  noneFloridaStateLicenseForAllRetailers = false;
  statesWithLicense = {
    California: ['CA', 'California'],
    Florida: ['FL', 'Florida'],
  };

  dropArea: IDropArea = RECEIPT_FILES_DROP_AREA;

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
    private readonly modalDialogService: ModalDialogService,
    private fb: FormBuilder,
    private claimFormService: ClaimFormService,
    private retailerPlanApiService: RetailerPlanApiService,
    private notificationService: NotificationService,
    private parentPlanApiService: ParentPlanApiService,
    private storesApi: StoresApi,
    private readonly retailersListStore: RetailersListStore,
    private readonly state: RxState<IRegisterRetailerPlanState>,
    private readonly userStore: UserStore,
    private readonly dialogService: DialogService,
  ) {
    super();
    this.retailersListStore.updateSearchRequest({
      retailerFilter: RetailerFilter.SellingProtectionPlan,
      page: 1,
    });
    this.retailersListStore.loadData();

    this.state.set({
      nextButtonDisabled: false,
    });
    this.state.connect(
      'consumerStepAlertMessage',
      this.userStore.select$('session', 'maxSalesRegistrationDays').pipe(
        map(maxSalesRegistrationDays => {
          const minRegistrationDaysDate = moment()
            .utc(true)
            .subtract(maxSalesRegistrationDays, 'days')
            .format('MM/DD/YYYY');
          // eslint-disable-next-line max-len
          return `You can only enter a plan sale if the delivery date was within the past <b>${maxSalesRegistrationDays}</b> days, i.e. no earlier than <b>${minRegistrationDaysDate}</b>. Future estimated delivery dates are allowed.`;
        }),
      ),
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.state.set({
      currentStep: this.currentStep,
    });
    this.form = this.fb.group({
      consumer: this.fb.group({
        info: this.claimFormService.getConsumerFields({}, false, {
          excludedFields: ['preferredContactLanguage', 'preferredContactMethod', 'preferredContactTime'],
        }),
        consumerAddress: this.claimFormService.getAddressFields({}, AddressType.CustomerAddress),
        serviceAddress: this.claimFormService.getAddressFields({}, AddressType.ServiceAddress),
        isServiceAddressSame: [false],
      }),
      consumerPlan: this.fb.group({
        retailerId: [null, Validators.required],
        purchaseDate: [this.purchaseDateInitial || null, Validators.compose([DateValidation, Validators.required])],
        receiptNumber: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      }),
      plan: this.fb.array([this.getPlanFields()]),
    });

    this.form
      .get('consumer.info.emailAddress')
      .setValidators([Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)]);
    this.form.get('consumer.info.emailAddress').updateValueAndValidity();
    this.state.set({
      isEmailRequired: false,
    });

    this.state.connect(
      'nextButtonDisabled',
      this.state.select('currentStep').pipe(
        map(currentStep => {
          if (currentStep.step === RegisterRetailerPlanSteps.Plan) {
            return (
              this.form.get('plan')['controls'].length > 0
              && !this.form.get('plan')['controls'][this.currentSubStep].valid
            );
          } else if (currentStep.formGroupName) {
            return this.form.get(currentStep.formGroupName).invalid;
          } else {
            return false;
          }
        }),
      ),
    );

    this.state.connect(
      'nextButtonDisabled',
      this.state.select('currentSubStep').pipe(
        withLatestFrom(this.state.select('currentStep')),
        filter(([currentSubStep, currentStep]) => currentStep.step === RegisterRetailerPlanSteps.Plan),
        map(([currentSubStep]) => {
          const planControl = this.form.get('plan') as FormArray;
          return planControl.length > 0 && !planControl.at(currentSubStep).valid;
        }),
      ),
    );

    this.state.connect(
      'nextButtonDisabled',
      this.form.statusChanges.pipe(
        switchMap(() =>
          this.state.select('currentStep').pipe(
            filter(currentStep => currentStep.step !== RegisterRetailerPlanSteps.Plan),
            filter(currentStep => !!currentStep.formGroupName),
            map(currentStep => this.form.get(currentStep.formGroupName).invalid),
          ),
        ),
      ),
    );

    this.state.connect(
      'nextButtonDisabled',
      this.form.get('plan').statusChanges.pipe(
        filter(() => this.form.get('plan')['controls'].length > 0),
        switchMap(() =>
          this.state.select('currentStep').pipe(
            filter(currentStep => currentStep.step === RegisterRetailerPlanSteps.Plan),
            map(() => this.form.get('plan')['controls'][this.currentSubStep].invalid),
          ),
        ),
      ),
    );

    this.checkAccountOnHoldAndFloridaLicenseForAllRetailers();
  }

  updatePlansForNewRetailer(retailerId: number): void {
    const plansPrev = this.form.get('plan').value;
    if (plansPrev.length && plansPrev.filter((plan: any) => plan.planId !== null).length > 0) {
      const searchRequest = {
        noCount: false,
        noRows: false,
        orderBy: [{ fieldName: 'name', ascending: true }],
        page: 1,
        pageSize: 10,
        retailerId,
        retailerPlanIds: plansPrev.map(plan => plan.planId),
      };

      this.parentPlanApiService.comparePlans(searchRequest).subscribe((plansNew: string[]) => {
        const plansToDelete: Array<{id: string; name: string}> = [];
        let message = '';

        this.plansNew = plansNew;
        this.selectedPlansIdsList = [];
        plansNew.forEach((planNewId, i) => {
          if (planNewId) {
            (this.form.get('plan') as FormArray).controls[i].get('planId').patchValue(planNewId);
            this.selectedPlansIdsList.push(planNewId);
          } else {
            (this.form.get('plan') as FormArray).controls[i].get('planId').patchValue(null);
            if (plansPrev[i].planId) {
              plansToDelete.push({
                id: plansPrev[i].planId,
                name: this.navigationItems.plan.child[i].name,
              });
              this.navigationItems.plan.child[i].name = `Plan ${i + 1}`;
            }
          }
        });
        this.form.markAsDirty();
        this.form.markAsTouched();

        if (plansToDelete.length) {
          message =
            plansToDelete.length === 1
              ? `This plan doesn't exist: ${plansToDelete[0].name}. Replace the plan's name or remove it.`
              : `These plans doesn't exist: ${plansPrev
                .map(plan => plan.planId)
                .join(';  ')} Replace the plans' names or remove them.`;
        }

        this.notificationService.next({
          message: 'Retailer was changed successfully. ' + message,
          type: NotificationType.Common,
          duration: 12000,
        });
      });
    }
  }

  getPlanFields(): FormGroup {
    return this.fb.group({
      planId: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      planPrice: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      totalCoverageAmount: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      deliveryDate: [this.deliveryDateInitial || null, Validators.compose([DateValidation, Validators.required])],
    });
  }

  onClose(): void {
    if (
      (this.currentSubStep !== this.preSteps.Search && this.currentSubStep !== this.preSteps.Select)
      || this.currentStep.step !== RegisterRetailerPlanSteps.Consumer
    ) {
      if (this.submitInProgress) {
        return;
      }
      this.dialogService.canLeaveDefaultConfirmationObservable(this.form).subscribe(canLeave => {
        if (canLeave) {
          this.sideDialogStateService.close();
        } else {
          return;
        }
      });
    } else {
      this.sideDialogStateService.close();
    }
  }

  initWizard(): void {
    this.navigationItems = {
      consumer: {
        name: 'Consumer',
        step: RegisterRetailerPlanSteps.Consumer,
        nextStep: 'sale',
        prevStep: null,
        formGroupName: 'consumer',
        readonly: false,
        child: [
          { name: 'Search', subStep: PreRegisterRetailerPlanSteps.Search, readonly: false },
          { name: 'Select', subStep: PreRegisterRetailerPlanSteps.Select, readonly: false },
          { name: 'StartRegistering', subStep: PreRegisterRetailerPlanSteps.Registration, readonly: false },
        ],
        hideChildrenInNavbar: true,
      },
      sale: {
        name: 'Sale',
        step: RegisterRetailerPlanSteps.Sale,
        nextStep: 'plan',
        prevStep: 'consumer',
        formGroupName: 'consumerPlan',
        readonly: false,
      },
      plan: {
        name: 'Plan',
        step: RegisterRetailerPlanSteps.Plan,
        nextStep: 'uploadReceipt',
        prevStep: 'sale',
        formGroupName: 'plan',
        child: [{ name: 'Plan 1', subStep: 0, readonly: false }],
        readonly: false,
      },
      uploadReceipt: {
        name: 'Upload Receipt',
        step: RegisterRetailerPlanSteps.UploadReceipt,
        nextStep: null,
        prevStep: 'plan',
        formGroupName: null,
        readonly: false,
        extraValidation: (step: RegisterRetailerPlanSteps, form: FormGroup) => !(step === RegisterRetailerPlanSteps.UploadReceipt && this.filesForm.value.length === 0),
      },
    };

    this.navigationList = ['consumer', 'sale', 'plan', 'uploadReceipt'];
    this.currentStep = this.navigationItems['consumer'];
    this.setSubStep(PreRegisterRetailerPlanSteps.Search);
  }

  submitForm(): void {
    if (this.submitInProgress) {
      return;
    }
    if (this.form.valid) {
      this.submitInProgress = true;
      const retailerPlanForm: RetailerPlanFormClass = new RetailerPlanFormClass();
      retailerPlanForm.oldConsumer = this.selectedConsumer;
      retailerPlanForm.setConsumer(this.form.value.consumer.info, this.form.value.consumer.consumerAddress);
      retailerPlanForm.setConsumerAddress(this.form.value.consumer.consumerAddress);
      retailerPlanForm.setServiceAddress(this.form.value.consumer.serviceAddress);
      retailerPlanForm.setConsumerPlan(this.form.value.consumerPlan);
      retailerPlanForm.setPlansInfo(this.form.value.plan);

      this.retailerPlanApiService
        .createProtectionPlan(retailerPlanForm.getValue(), this.filesForm.value)
        .pipe(
          tap(() => {
            this.sideDialogStateService.close();
          }),
          finalize(() => {
            this.submitInProgress = false;
          }),
        )
        .subscribe();
    }
  }

  onConsumerClicked(consumer: QuickSearchConsumer) {
    this.selectedConsumer = consumer;
    this.form.get('consumer.info.mobilePhone').setValidators([]);
    this.form.get('consumer.info.homePhone').setValidators([]);
    this.form.get('consumer.info.workPhone').setValidators([]);
    this.form.get('consumer.info').patchValue(consumer);

    if (consumer.emailAddress) {
      this.form
        .get('consumer.info.emailAddress')
        .setValidators([Validators.required, Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)]);
      this.state.set({
        isEmailRequired: true,
      });
    } else {
      this.form
        .get('consumer.info.emailAddress')
        .setValidators([Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)]);
      this.state.set({
        isEmailRequired: false,
      });
    }
    this.form.get('consumer.info.emailAddress').updateValueAndValidity();
    this.form.get('consumer.info.emailAddress').markAsTouched();

    this.form
      .get('consumer.consumerAddress')
      .patchValue(this.claimFormService.getAddressFields(consumer, AddressType.CustomerAddress).value);
    this.form.get('consumer.serviceAddress').patchValue(
      this.claimFormService.getAddressFields(
        {
          streetAddress1: consumer.serviceStreetAddress1,
          streetAddress2: consumer.serviceStreetAddress2,
          city: consumer.serviceCity,
          stateProvince: consumer.serviceState,
          postalCode: consumer.servicePostalCode,
        },
        AddressType.ServiceAddress,
      ).value,
    );
    this.navigationItems.consumer.readonly = true;
  }

  goToRegistrationStep($event: any) {
    this.form.get('consumer.info.mobilePhone').setValidators(this.claimFormService.getPhoneValidations(false));
    this.form.get('consumer.info.homePhone').setValidators(this.claimFormService.getPhoneValidations(false));
    this.form.get('consumer.info.workPhone').setValidators(this.claimFormService.getPhoneValidations(false));
    this.form.get('consumer.info.mobilePhone').updateValueAndValidity();
    this.form.get('consumer.info.homePhone').updateValueAndValidity();
    this.form.get('consumer.info.workPhone').updateValueAndValidity();
    this.form
      .get('consumer.info.emailAddress')
      .setValidators([Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)]);
    this.form.get('consumerPlan.purchaseDate').updateValueAndValidity();
    this.state.set({
      isEmailRequired: false,
    });
    this.setSubStep(this.preSteps.Registration);
    this.navigationItems.consumer.readonly = false;
  }

  prevStep() {
    if (
      this.currentStep.step === RegisterRetailerPlanSteps.Consumer
      && this.currentSubStep === PreRegisterRetailerPlanSteps.Registration
    ) {
      this.resetForm();
      if ((this.form.get('consumer.info') as FormGroup).get('firstName').enabled) {
        this.setSubStep(PreRegisterRetailerPlanSteps.Search);
      } else {
        super.prevStep();
      }
    } else {
      super.prevStep();
    }
  }

  resetForm() {
    this.form.reset();
    this.form.get('consumerPlan.purchaseDate').patchValue(this.purchaseDateInitial || null);
    this.form.get('consumerPlan.purchaseDate').updateValueAndValidity();
  }

  addPlan() {
    const planSubStep = this.currentSubStep + 1;
    (this.form.get('plan') as FormArray).insert(planSubStep, this.getPlanFields());
    this.navigationItems.plan.child.splice(planSubStep, 0, this.returnPlanChild(planSubStep));
    this.updatePlanChildIndexes();
    this.setSubStep(planSubStep);
  }

  removePlan(index: number) {
    if (index !== 0) {
      this.prevStep();
    }
    (this.form.get('plan') as FormArray).removeAt(index);
    this.navigationItems.plan.child.splice(index, 1);
    this.updatePlanChildIndexes();
    this.selectedPlansIdsList = this.form.get('plan').value.map(formPlan => formPlan.planId);
  }

  updatePlanChildIndexes() {
    this.navigationItems.plan.child = this.navigationItems.plan.child.map((planChild, subStep) => ({
      ...planChild,
      name: planChild.name.startsWith('Plan ') ? `Plan ${subStep + 1}` : planChild.name,
      subStep,
    }));
  }

  protectionPlanSelected(plan: Identity | IIdentityString): void {
    this.selectedPlansIdsList = this.form.get('plan').value.map(formPlan => formPlan.planId);
    const planName = plan.name
      ? plan.name
      : plan.parentPlanName
        ? plan.parentPlanName
        : this.navigationItems.plan.child[this.currentSubStep].name;
    this.navigationItems.plan.child[this.currentSubStep].name = planName;
  }

  plansUpdated(plans: Array<IIdentityString>): void {
    this.plans = plans;
  }

  nextStep() {
    if (this.currentStep.step === RegisterRetailerPlanSteps.Sale) {
      if (this.selectedRetailer.isAccountingOnHold) {
        this.modalDialogService.open({
          title: 'Service Suspended',
        }, ServiceSuspendedDialogComponent, DIALOG_CONFIG_RETAILER_SALE);
      } else if (
        this.isFromFloridaState(this.selectedRetailer)
        && !this.hasFloridaStateLicense(this.selectedRetailer)
      ) {
        this.modalDialogService.open({
          title: 'Florida License Required',
          data: {
            allRetailersDontHaveFloridaLicenses: false,
          },
        }, LicenseFloridaDialogComponent, DIALOG_CONFIG_RETAILER_SALE);
      } else if (this.isFromCaliforniaState(this.selectedRetailer) && !this.hasFloridaStateLicense(this.selectedRetailer)) {
        const retailerId = this.selectedRetailer.id;
        this.modalDialogService.open<LicenseCaliforniaDialogComponent, null>({
          title: 'California License Required',
          data: { retailerId },
        }, LicenseCaliforniaDialogComponent, DIALOG_CONFIG_RETAILER_SALE);
      } else {
        super.nextStep();
      }
    } else {
      super.nextStep();
    }
  }

  isFromCaliforniaState(retailer: IRetailer): boolean {
    const retailerState = retailer.state ? retailer.state.toLowerCase() : '';
    const statesWithLicense = <string[]>this.statesWithLicense.California.map(name => name.toLowerCase());
    return statesWithLicense.includes(retailerState);
  }

  selectRetailer(retailer: IRetailer) {
    if (retailer) {
      this.selectedRetailer = retailer;
      const cityPartAddress = [retailer.addressCity, retailer.addressPostalCode].filter(part => !!part).join(' ');
      const stateCityAddress = `${[retailer.state, cityPartAddress].filter(part => !!part).join(', ')}`;
      this.selectedRetailerAddress = `${[retailer.streetAddress1, stateCityAddress]
        .filter(part => !!part)
        .join('<br/>')}`;
    }
  }

  checkFloridaStateLicenses(retailers: IRetailer[]) {
    const noLicenses = [];
    retailers.forEach(retailer => {
      if (this.isFromFloridaState(retailer) && !this.hasFloridaStateLicense(retailer)) {
        noLicenses.push(retailer.id);
      }
    });
    if (noLicenses.length === retailers.length) {
      this.noneFloridaStateLicenseForAllRetailers = true;
    }
    if (this.noneFloridaStateLicenseForAllRetailers) {
      this.modalDialogService.open({
        title: 'Florida License Required',
        data: {
          allRetailersDontHaveFloridaLicenses: true,
        },
      }, LicenseFloridaDialogComponent, DIALOG_CONFIG_RETAILER_SALE).afterClosed().subscribe(() => {
        this.sideDialogStateService.close();
      });
    }
  }

  checkAccountOnHoldAndFloridaLicenseForAllRetailers() {
    return this.storesApi
      .getValidateFppRetailers()
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(response => {
        if (this.form.get('consumer.retailerId')) {
          this.form
            .get('consumer.retailerId')
            .valueChanges.pipe(takeUntil(this.componentDestroyed$))
            .subscribe(retailerId => {
              this.updatePlansForNewRetailer(retailerId);
            });
        }

        if (this.form.get('consumer.isServiceAddressSame')) {
          this.form
            .get('consumer.isServiceAddressSame')
            .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$))
            .subscribe(isServiceAddressSame => {
              if (isServiceAddressSame) {
                this.form.get('consumer.serviceAddress').patchValue({
                  ...(this.form.get('consumer.consumerAddress') as FormGroup).getRawValue(),
                });
              }
            });
        }

        if (response.allRetailersOnHold) {
          this.modalDialogService.open({
            title: 'Service Suspended',
          }, ServiceSuspendedDialogComponent, DIALOG_CONFIG_RETAILER_SALE).afterClosed().subscribe(() => {
            this.sideDialogStateService.close();
          });
        }
        if (response.allRetailersDontHaveFloridaLicenses) {
          this.modalDialogService.open({
            title: 'Florida License Required',
            data: {
              allRetailersDontHaveFloridaLicenses: true,
            },
          }, LicenseFloridaDialogComponent, DIALOG_CONFIG_RETAILER_SALE).afterClosed().subscribe(() => {
            this.sideDialogStateService.close();
          });
        }
      });
  }

  isFromFloridaState(retailer: IRetailer): boolean {
    const retailerState = retailer.state ? retailer.state.toLowerCase() : '';
    const statesWithLicense = <string[]>this.statesWithLicense.Florida.map(name => name.toLowerCase());
    return statesWithLicense.includes(retailerState);
  }

  hasFloridaStateLicense(retailer: IRetailer): boolean {
    return !!retailer.floridaStateLicense;
  }

  removeFiles(fileIndex: number) {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    this.filesForm.clear();
    updatedFiles.forEach(file => {
      this.filesForm.push(this.fb.control(file));
    });
  }

  updateServiceAddress() {
    if (this.form.get('consumer.isServiceAddressSame').value) {
      this.form.get('consumer.serviceAddress').patchValue({
        ...(this.form.get('consumer.consumerAddress') as FormGroup).getRawValue(),
      });
    }
  }

  addressVerificationInProgress(addressVerificationInProgress: boolean) {
    this.state.set({
      addressVerificationInProgress,
    });
  }

  protected returnPlanChild(subStep: number): WizardNavigationChild {
    return {
      name: `Plan ${subStep + 1}`,
      subStep,
      readonly: false,
    };
  }

  protected setStep(stepName: string, subStep: number = 0) {
    super.setStep(stepName, subStep);
    this.state.set({
      currentStep: this.currentStep,
    });
  }

  protected setSubStep(subStep: number) {
    super.setSubStep(subStep);
    this.state.set({
      currentSubStep: subStep,
    });
  }
}
